body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  /* height: 60px; */
  box-shadow: 0 1px 9px -3px rgb(0 0 0 / 20%);
  justify-content: center;
  align-items: center;
}

/* support page */
.support-box-wrapper li .support-box:hover .support-image {
  transform: translateY(-5px);
}

.support-box-wrapper li .support-box .support-image {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin: 20px auto 32px;
  background: #fff;
  border-radius: 40px;
  transition: box-shadow .3s ease-out,transform .3s ease-out;
}

.iconBox {
  box-shadow: rgb(47 84 235 / 12%) 0px 6px 12px;
}

/* HIDE RADIO */
Input[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
Input[type=radio] + Image {
  cursor: pointer;
}

/* CHECKED STYLES */
Input[type=radio]:checked + Image {
  background-color: red;
}

.table-rows {
  cursor: pointer;
}

.fit-content {
  word-break: break-all;
}
