/* .card-container{
    white-space: nowrap;
    overflow-y: auto;
  }
  .card-container .card{
    display: inline-block;
    border: 1px solid #aaa;
    padding: 15px;
  } */

  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
  }
  .scrolling-wrapper .card {
    display: inline-block;
  }

  .scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .scrolling-wrapper-flexbox .card {
    flex: 0 0 auto;
  }

  .scrolling-wrapper {
    -webkit-overflow-scrolling: touch;
  }
  